$(function () {
    function isMobile() {
        var windows_width = $(window).width();
        console.log(windows_width);
        if (windows_width > 768) {
            $('body').append('<link id="print_pc" rel="stylesheet" href="/css/subpage/search_detail_print_pc.min.css">')
        }
        else {
            $('body').append('<link id="print_sp" rel="stylesheet" href="/css/subpage/search_detail_print_sp.min.css">')
        }
    };

    isMobile()

});
